import {Component, EventEmitter, Input, Output} from '@angular/core';
import { EnvService } from '../../env.service';

@Component({
  selector: 'app-geo-smallareaincome-wrapper',
  template: `
    <micro-geo-smallareaincome *axLazyElement="link, errorTemplate: error" [dataFromParent]="dataToChild"></micro-geo-smallareaincome>
    <ng-template #error>
      <app-micro-error></app-micro-error>
    </ng-template>
  `,
  styles: [
  ]
})
export class GeoSmallAreaIncomeWrapperComponent {

  link = 'http://host.docker.internal:3012/main.js';
  @Input() dataToChild: string | undefined;
  @Output() dataFromChild = new EventEmitter<string>();
  listOfData: string[] = [];

  dataToParent($event: CustomEvent) {
    this.dataFromChild.emit($event.detail);
  }

  constructor (private env: EnvService) {
    if(env.enableDebug) {
      // console.log('user management micro frontend configured URL: ' + env.geoSmallAreaIncomeFrontEndURL);
    }
    //setup the URL for the micro-frontend
    // if (env.geoSmallAreaIncomeFrontEndURL){
    //   this.link = env.geoSmallAreaIncomeFrontEndURL;
    // }
  }

}
