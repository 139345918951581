import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LazyElementsModule} from '@angular-extensions/elements';
import { GeoCensusDemographicWrapperComponent } from './geo-censusdemographic-wrapper.component';
import { SharedModule } from '../../modules/shared/shared.module';


@NgModule({
  declarations: [GeoCensusDemographicWrapperComponent],
  imports: [
    CommonModule,
    LazyElementsModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [GeoCensusDemographicWrapperComponent]
})
export class GeoCensusDemographicWrapperModule { }
