<div class="side-nav" [ngClass]="{'collapsed': collapsed}">
  <div class="logo-container">
    <img src="assets/nav/all-white-logo.png">
    <span *ngIf="!collapsed" class="ms-4">Platform Name</span>
  </div>
  <ul class="navbar-nav">
    <li routerLinkActive="active" routerLink="home">
        <i class="fas fa-home"></i>
        <span *ngIf="!collapsed" class="ms-3">Home</span>
    </li>
    <li routerLinkActive="active" routerLink="basics/search">
        <i class="fa fa-search"></i>
        <span *ngIf="!collapsed" class="ms-3">Search</span>
    </li>
    <li routerLinkActive="active" routerLink="basics/admin-management">
        <i class="fas fa-cog"></i>
        <span *ngIf="!collapsed" class="ms-3">Admin Preferences</span>
    </li>
  </ul>
</div>