<div class="main-contain d-flex">
  <div class="topbar-container position-fixed top-0 end-0">
    <app-top-nav (sidebarButtonClicked)="toggleSidebarExpansion()" (logoutClicked)="handleLogout();"></app-top-nav>
  </div>  
  <div class="container-fluid body-container">
    <div class="main-wrapper">
      <router-outlet></router-outlet>
    </div>
    <div class="footer-wrapper">
      <app-footer></app-footer>
    </div>
  </div>
</div>