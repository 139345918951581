import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavComponent } from './top-nav/top-nav.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { FooterComponent } from './footer/footer.component';
import { NavSearchComponent } from './top-nav/nav-search/nav-search.component';
import { FormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { FallbackComponent } from '../../fallback.component';
import { ShouldLoginComponent } from '../../should-login.component';

@NgModule({
  declarations: [
    TopNavComponent,
    SideNavComponent,
    FooterComponent,
    NavSearchComponent
  ],
  exports: [
    TopNavComponent,
    SideNavComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', loadChildren: () => import('../../feature-basics/basics.module').then(m => m.BasicsModule) },
      { path: 'maps', children: [
        { path: '', redirectTo: 'geo-employment', pathMatch: 'full' },
        { path: 'geo-census-demographic', loadChildren: () => import('../geo-census-demographic/geo-census-demographic.module').then(m => m.GeoCensusDemographicModule) },
        { path: 'geo-digital-equity', loadChildren: () => import('../geo-digital-equity/geo-digital-equity.module').then(m => m.GeoDigitalEquityModule) },
        { path: 'census-flows', loadChildren: () => import('../geo-employment/geo-employment.module').then(m => m.GeoEmploymentModule) },
        { path: 'geo-historical-apportionment', loadChildren: () => import('../geo-historical-apportionment/geo-historical-apportionment.module').then(m => m.GeoHistoricalApportionmentModule) },
        { path: 'geo-poverty', loadChildren: () => import('../geo-poverty/geo-poverty.module').then(m => m.GeoPovertyModule) },
        { path: 'geo-rural', loadChildren: () => import('../rural/rural.module').then(m => m.RuralModule) }
      ]},
      { path: 'should-login', component: ShouldLoginComponent },
      { path: '**', component: FallbackComponent },
  ], {}),
  ]
})
export class NavigationModule { }
