import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LazyElementsModule} from '@angular-extensions/elements';
import { GeoSmallAreaIncomeWrapperComponent } from './geo-smallareaincome-wrapper.component';
import { SharedModule } from '../../modules/shared/shared.module';


@NgModule({
  declarations: [GeoSmallAreaIncomeWrapperComponent],
  imports: [
    CommonModule,
    LazyElementsModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [GeoSmallAreaIncomeWrapperComponent]
})
export class GeoSmallAreaIncomeWrapperModule { }
