import {APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppMenuComponent } from './app-menu.component';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { FallbackComponent } from './fallback.component';
import { ShouldLoginComponent } from './should-login.component';
import { NavigationModule } from './modules/navigation/navigation.module';
import {LazyElementsModule} from '@angular-extensions/elements';
import { EnvServiceProvider } from './env.service.provider';
import { AppConfigService } from './app-config-service.service';

import { UserManagementWrapperModule } from './webcomponents/user-management-wrapper/user-management-wrapper.module';
import { GeoEmploymentWrapperModule } from './webcomponents/geo-employment-wrapper/geo-employment-wrapper.module';
import { GeoSmallAreaIncomeWrapperModule } from './webcomponents/geo-smallareaincome-wrapper/geo-smallareaincome-wrapper.module';
import { GeoHistoricalApportionmentWrapperModule } from './webcomponents/geo-historicalapportionment-wrapper/geo-historicalapportionment-wrapper.module';
import { GeoDigitalEquityWrapperModule } from './webcomponents/geo-digitalequity-wrapper/geo-digitalequity-wrapper.module';
import { GeoCensusDemographicWrapperModule } from './webcomponents/geo-censusdemographic-wrapper/geo-censusdemographic-wrapper.module';
import { RuralWrapperModule } from './webcomponents/rural-wrapper/rural-wrapper.module';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [
    AppComponent,
    AppMenuComponent,
    FallbackComponent,
    ShouldLoginComponent
    ],
  providers: [EnvServiceProvider
    ,{provide : APP_INITIALIZER, multi : true, deps : [AppConfigService],  useFactory : (appConfigService : AppConfigService) =>  () => appConfigService.loadAppConfig()}
  ],
  imports: [
    UserManagementWrapperModule,
    GeoEmploymentWrapperModule,
    GeoSmallAreaIncomeWrapperModule,
    GeoHistoricalApportionmentWrapperModule,
    GeoDigitalEquityWrapperModule,
    GeoCensusDemographicWrapperModule,
    RuralWrapperModule,
    LazyElementsModule,
    BrowserModule,
    NavigationModule,
    CoreModule.forRoot(),
    RouterModule.forRoot([
      { path: 'basics', loadChildren: () => import('./feature-basics/basics.module').then(m => m.BasicsModule) },
      { path: '**', component: FallbackComponent },
    ], {}),
    FontAwesomeModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
