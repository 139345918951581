/* eslint-disable brace-style */
/* eslint-disable max-len */

import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from './core/auth.service';
import { AppConfigService } from './app-config-service.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  isAuthenticated$: Observable<boolean>;
  isDoneLoading$: Observable<boolean>;
  canActivateProtectedRoutes$: Observable<boolean>;

  title = 'parent';
  data: any = 'something from parent!!!';
  authenticationData: any = this.authService.oauthServiceObject;
  sideBarCollapsed: boolean = true;
  sideBarDisabled: boolean = true;

  constructor(
    private authService: AuthService, private appConfigService: AppConfigService
  ) {
    //This variables are stored in assets/config/config.json
    //once angular is compiled you can replace these values in that file to change any of its values
    if(appConfigService.enableDebug) {
      console.log('Debug mode enabled!');
    }
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.isDoneLoading$ = this.authService.isDoneLoading$;
    this.canActivateProtectedRoutes$ = this.authService.canActivateProtectedRoutes$;
  }

  login() { this.authService.login(); }
  logout() { this.authService.logout(); }
  refresh() { this.authService.refresh(); }
  reload() { window.location.reload(); }
  clearStorage() { localStorage.clear(); }

  logoutExternally() {
    window.open(this.authService.logoutUrl);
  }

  toggleSidebarExpansion() {
    this.sideBarCollapsed = !this.sideBarCollapsed
  }

  handleLogout() {
    this.logout();
  }

  get hasValidToken() { return this.authService.hasValidToken(); }
  get accessToken() { return this.authService.accessToken; }
  get refreshToken() { return this.authService.refreshToken; }
  get identityClaims() { return this.authService.identityClaims; }
  get idToken() { return this.authService.idToken; }
}
