import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LazyElementsModule} from '@angular-extensions/elements';
import { GeoHistoricalApportionmentWrapperComponent } from './geo-historicalapportionment-wrapper.component';
import { SharedModule } from '../../modules/shared/shared.module';


@NgModule({
  declarations: [GeoHistoricalApportionmentWrapperComponent],
  imports: [
    CommonModule,
    LazyElementsModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [GeoHistoricalApportionmentWrapperComponent]
})
export class GeoHistoricalApportionmentWrapperModule { }
